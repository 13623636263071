<form #form="ngForm">
  <mt-select
    *field="'audience'"
    name="audience"
    [(ngModel)]="formSig().audience"
    [options]="formOptionsSig().asset_audience"
    placeholder="Asset audience"
    label="Asset audience"
    [required]="true"
  ></mt-select>
  <mt-select
    *field="'visibility'"
    name="visibility"
    [(ngModel)]="formSig().visibility"
    [options]="formOptionsSig().visibility"
    placeholder="Visibility"
    label="Visibility"
    [required]="true"
    (sl-change)="onVisibilitySelect()"
  ></mt-select>

  @if (showVisiblityWarning()) {
    <mt-warning>
      Alert: You are uploading a sensitive document type and selected external visibility (i.e
      distributors will see it). Change to internal visibility if you do not want this.
    </mt-warning>
  }

  <mt-select
    *field="'languages'"
    name="language"
    [options]="formOptionsSig().language"
    [(ngModel)]="formSig().languages"
    placeholder="Language"
    label="Language"
    [required]="true"
    [multiple]="true"
  ></mt-select>

  @if (formSig().contentType === TYPE_SERVICE_BULLETIN) {
    <mt-input
      *field="'serviceBulletinNumber'"
      [(ngModel)]="formSig().serviceBulletinNumber"
      name="bulletinNumber"
      label="Bulletin number"
      placeholder="Bulletin number"
      [maxlength]="10"
      [required]="true"
    ></mt-input>
    <mt-select
      *field="'serviceBulletinNumber'"
      name="bulletinClassification"
      [options]="formOptionsSig().sb_classification"
      [(ngModel)]="formSig().serviceBulletinClassification"
      placeholder="Bulletin classification"
      label="Bulletin classification"
      [required]="true"
    ></mt-select>
  }
  <mt-textarea
    *field="'description'"
    placeholder="Content description"
    label="Content description"
    name="contentDescription"
    [(ngModel)]="formSig().description"
  ></mt-textarea>
  <mt-input
    placeholder="Document number"
    label="Document number"
    name="documentNumber"
    [(ngModel)]="formSig().documentNumber"
  ></mt-input>
  <mt-textarea
    *field="'externalUrl'"
    placeholder="External url "
    label="External url"
    name="externalUrl"
    [(ngModel)]="formSig().externalUrl"
  ></mt-textarea>
  <mt-select
    *field="'measures'"
    name="measures"
    [clearable]="true"
    [options]="formOptionsSig().measure"
    placeholder="Measures"
    label="Measures"
    [multiple]="true"
    [(ngModel)]="formSig().measures"
  ></mt-select>
  <div class="dates">
    <mt-date-picker
      *field="'validFrom'"
      label="Valid from"
      name="validFrom"
      [(ngModel)]="formSig().validFrom"
      [min]="currentDate"
      (input)="validateDateRange()"
    ></mt-date-picker>
    <mt-date-picker
      *field="'validTo'"
      label="Valid to"
      name="validTo"
      [(ngModel)]="formSig().validTo"
      [min]="currentDate"
      (input)="validateDateRange()"
    ></mt-date-picker>
    @if (!isDateRangeValid) {
      <p>The current date range is not valid</p>
    }
  </div>

  <mt-checkbox
    *field="'syncWithShowpad'"
    name="syncWithShowpad"
    [(ngModel)]="formSig().syncWithShowPad"
  >
    Sync with Showpad
  </mt-checkbox>

  <mt-checkbox
    *field="'productRelated'"
    (input)="clearProductRelated()"
    name="productRelated"
    [(ngModel)]="formSig().productRelated"
    [disabled]="isProductVerificationLoading()"
  >
    Product related document
  </mt-checkbox>
</form>
