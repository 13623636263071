<mt-steps [currentStep]="currentStep()" [steps]="steps"></mt-steps>

<h2>{{ steps[currentStep()].title }}</h2>

@if (!isFormEditableSig()) {
  <form #form="ngForm">
    <mt-upload
      name="file"
      accept="video/*,image/*, .pdf, .xlsx, .docx, .doc"
      [(ngModel)]="formFileSig().uploadFile"
      [required]="true"
    ></mt-upload>
  </form>
} @else {
  <mt-asset-preview [file]="assetPreviewSig()"> </mt-asset-preview>
}

@switch (currentStep()) {
  @case (0) {
    <mt-upload-form-steps-category></mt-upload-form-steps-category>
  }
  @case (1) {
    <mt-upload-form-steps-file-details></mt-upload-form-steps-file-details>
    @if (formSig().productRelated) {
      <mt-upload-form-steps-product-information></mt-upload-form-steps-product-information>
    }
  }
  @case (2) {
    <mt-upload-form-steps-organization></mt-upload-form-steps-organization>
  }
  @case (3) {
    <mt-upload-form-steps-title-generation></mt-upload-form-steps-title-generation>
  }
  @case (4) {
    <mt-upload-form-steps-summary
      (showFormStepEvent)="showFormStep($event)"
    ></mt-upload-form-steps-summary>
  }
}

<div class="actions">
  <mt-button
    [disabled]="isProductVerificationLoading()"
    (submitEvent)="onCancel()"
    buttonStyle="primary"
    [outline]="true"
    >{{ buttonPrevText() }}</mt-button
  >
  <mt-button [disabled]="isNextStepDisabled()" (submitEvent)="onSubmit()">{{
    buttonNextText()
  }}</mt-button>
</div>
