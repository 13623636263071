import { Asset } from '@my-toolbox-fe/type-asset';

export const mockAsset: Asset = {
  id: 28891,
  audience: { code: 'asset_audience', name: 'Local', value: 'Local' },
  brands: [{ code: 'brand', name: '66', value: '66' }],
  businessLines: [{ code: 'business_line', name: 'APE', value: 'APE' }],
  category: { code: 'category', name: 'Technical documents', value: '3' },
  countries: [{ code: 'country', name: 'BE', value: 'BE' }],
  created: '18/09/2024',
  description: { code: 'description_content', name: 'desc', value: 'desc' },
  documentNumber: { code: 'document_number', name: '1', value: '1' },
  editable: true,
  externalUrl: { code: 'external_url', name: 'url', value: 'url' },
  languages: [{ code: 'language', name: 'KO', value: 'KO' }],
  lastModified: '18/09/2024',
  measures: [{ code: 'measure', name: 'Imperial', value: 'Imperial' }],
  productCompanies: [{ code: 'pim_product_company', name: 'Antwerp', value: 'APP' }],
  realFileName: 'mock DataSheet AVR KO Antwerp 1.pdf',
  restrictedRoles: [],
  size: '0 bytes',
  status: { code: 'status', name: 'Active', value: 'Active' },
  syncWithShowPad: { code: 'showpad', name: 'No', value: 'No' },
  title: 'mock DataSheet AVR KO Antwerp 1',
  type: { code: 'type_technical', name: 'DataSheet AVR', value: 'DataSheet AVR' },
  tags: [],
  thumbnailUrl: '',
  downloadUrl: 'https://mytoolbox-dev.atlascopco-powertechnique.com/asset/28891',
  visibility: { code: 'visibility', name: 'Internal', value: 'Internal' },
  partNumbers: [],
  productModels: [],
  productRanges: [],
  productSubRanges: [],
  productNames: [],
  serviceBulletinClassification: { code: '', name: '', value: '' },
  serialNumbers: [],
  serviceBulletinNumber: { code: '', name: '', value: '' },
  validFrom: '',
  validTo: '',
};
