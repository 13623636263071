<mt-summary title="File details" [items]="fileSummary">
  <mt-button (submitEvent)="onShowFormStep(1)">
    <mt-icon-edit icon></mt-icon-edit>
    Details
  </mt-button>
</mt-summary>
<mt-summary title="Product information" [items]="productSummary"></mt-summary>
<mt-summary title="Organization" [items]="organizationSummary">
  <mt-button (submitEvent)="onShowFormStep(2)">
    <mt-icon-edit icon></mt-icon-edit>
    Details
  </mt-button>
</mt-summary>
