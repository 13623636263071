import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import {
  AlertService,
  ButtonUiComponent,
  DynamicInputUiComponent,
  InputUiComponent,
  SelectUiComponent,
  SkeletonUiComponent,
  SummaryUiComponent,
} from '@my-toolbox-fe/ui-elements';
import {
  ProductInformation,
  ProductSuggestion,
  UploadOptionsFilters,
} from '@my-toolbox-fe/type-upload';
import {
  ProductVerificationService,
  ShowFieldDirective,
  UploadFormStateService,
} from '@my-toolbox-fe/data-access-upload';
import { filterItems, UPLOAD_MODE_COPY, UPLOAD_MODE_EDIT } from '@my-toolbox-fe/shared';

@Component({
  selector: 'mt-upload-form-steps-product-information',
  standalone: true,
  imports: [
    CommonModule,
    InputUiComponent,
    FormsModule,
    SelectUiComponent,
    ButtonUiComponent,
    DynamicInputUiComponent,
    SummaryUiComponent,
    ShowFieldDirective,
    SkeletonUiComponent,
  ],
  templateUrl: './upload-form-steps-product-information.smart-component.html',
  styleUrl: './upload-form-steps-product-information.smart-component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UploadFormStepsProductInformationSmartComponent implements OnInit, OnDestroy {
  protected productVerificationService = inject(ProductVerificationService);

  protected uploadFormStateService = inject(UploadFormStateService);

  private alertService = inject(AlertService);

  formOptionsSig: WritableSignal<UploadOptionsFilters> = this.uploadFormStateService.formOptionsSig;

  formSig = this.uploadFormStateService.formSig;

  productSuggestionSig: WritableSignal<ProductSuggestion> =
    this.productVerificationService.productSuggestionSig;

  formProductSig: WritableSignal<ProductInformation> = signal({
    serialNumbers: [],
    partNumbers: [],
    productNames: [],
    productModels: [],
    productSubranges: [],
    productRanges: [],
  });

  formProductStateSig: WritableSignal<ProductInformation> =
    this.uploadFormStateService.formProductSig;

  isFormEditableSig: WritableSignal<boolean> = this.uploadFormStateService.isFormEditableSig;
  formModeSig: WritableSignal<string> = this.uploadFormStateService.formModeSig;

  productSuggestionHasErrors: Signal<boolean> =
    this.productVerificationService.productSuggestionHasErrors;

  formOrganizationSig = this.uploadFormStateService.formOrganizationSig;

  suggestionSummarySig: WritableSignal<{ label: string; value: string }[]> =
    this.productVerificationService.suggestionSummarySig;

  summary: any = [];

  hasFormInputValues = false;

  isLoadingSig = signal(false);

  @ViewChild('form') ngForm: NgForm | null = null;

  emailSubject = 'MyToolbox - Upload - Tag request or problem ';
  emailString = '';

  filterValues(arr: string[]) {
    return arr.filter((item) => item !== '');
  }

  ngOnInit(): void {
    if (this.formModeSig() === UPLOAD_MODE_EDIT || this.formModeSig() === UPLOAD_MODE_COPY) {
      this.setProductSuggestion();
    }
    this.checkStepValidation();
    this.buildMailUrl();
  }

  ngOnDestroy(): void {
    this.uploadFormStateService.setIsNextStepDisabled(false);
  }

  setProductSuggestion() {
    this.productVerificationService.setSuggestionSummary(this.formProductStateSig());
  }

  hasFormValues(): boolean {
    const items = this.formProductSig();
    return Object.values(items).some((item) => {
      return item.length > 0 && item[0] !== '';
    });
  }

  hasSuggestionValues(): boolean {
    const items: { label: string; value: string }[] = this.suggestionSummarySig();
    return Object.values(items).some((item) => {
      return item.value !== '';
    });
  }

  checkStepValidation() {
    const hasSuggestionSummaryValues = this.hasSuggestionValues();

    if (this.formSig().productRelated && !hasSuggestionSummaryValues) {
      return this.uploadFormStateService.setIsNextStepDisabled(true);
    }

    if (this.formSig().productRelated && !this.productSuggestionHasErrors()) {
      this.uploadFormStateService.setIsNextStepDisabled(false);
    }
  }

  async validateProducts() {
    this.hasFormInputValues = this.hasFormValues();

    if (!this.hasFormInputValues) {
      return;
    }

    this.isLoadingSig.set(true);

    const data = {
      partNumbers: this.filterValues(this.formProductSig().partNumbers),
      serialNumbers: this.filterValues(this.formProductSig().serialNumbers),
      productNames: this.filterValues(this.formProductSig().productNames),
      productModels: filterItems(
        this.formProductSig().productModels,
        this.formOptionsSig().product_model,
      ),
      productSubranges: filterItems(
        this.formProductSig().productSubranges,
        this.formOptionsSig().product_subrange,
      ),
      productRanges: filterItems(
        this.formProductSig().productRanges,
        this.formOptionsSig().product_range,
      ),
    };

    await this.productVerificationService
      .getProductVerification(data)
      .then(() => {
        this.uploadFormStateService.setIsNextStepDisabled(this.productSuggestionHasErrors());

        const product = {
          productModels: this.productSuggestionSig().suggested.models,
          productNames: this.productSuggestionSig().suggested.names,
          productRanges: this.productSuggestionSig().suggested.ranges,
          productSubranges: this.productSuggestionSig().suggested.subranges,
          serialNumbers: this.productSuggestionSig().suggested.serialNumbers,
          partNumbers: this.productSuggestionSig().suggested.partNumbers,
        };

        this.uploadFormStateService.setIsSingleBrandValid(
          this.productSuggestionSig().suggested.brands.length <= 1,
        );

        this.buildMailUrl();

        this.uploadFormStateService.setOrganization({
          ...this.formOrganizationSig(),
          productCompanies: this.productSuggestionSig().suggested.companies,
          brands:
            this.productSuggestionSig().suggested.brands.length > 1
              ? []
              : this.productSuggestionSig().suggested.brands,
          countries: this.productSuggestionSig().suggested.countries,
          businessLines: this.productSuggestionSig().suggested.businessLines,
        });

        this.uploadFormStateService.setProductInformation(product);
        this.productVerificationService.setSuggestionSummary(product);
        this.isLoadingSig.set(false);
      })
      .catch(() => {
        this.alertService.open({
          message: 'Something went wrong, try again',
          variant: 'danger',
        });
      })
      .finally(() => {
        this.isLoadingSig.set(false);
      });
  }

  buildMailUrl() {
    const body = this.productSuggestionSig()
      .errors.map((error) => {
        return `${error.reason} ${error.subject}: ${error.arguments.length ? error.arguments.join(', ') : ' invalid'}%0D%0A`;
      })
      .join(' ');

    this.emailString = `mailto:pt.cx@support.atlascopco.com?subject=${encodeURIComponent(this.emailSubject)}&body=${body}`;
  }
}
