import { Component, computed, inject, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFormStepsContainerSmartComponent } from '../upload-form-steps/upload-form-steps-container/upload-form-steps-container.smart-component';
import {
  ErrorUiComponent,
  LoaderUiComponent,
  SkeletonUiComponent,
} from '@my-toolbox-fe/ui-elements';
import {
  UploadFormEditBuilderService,
  UploadFormOptionsService,
  UploadFormStateService,
} from '@my-toolbox-fe/data-access-upload';
import { ActivatedRoute } from '@angular/router';
import { FormMode, UploadOptionsFilters } from '@my-toolbox-fe/type-upload';
import { UPLOAD_MODE_COPY, UPLOAD_MODE_CREATE, UPLOAD_MODE_EDIT } from '@my-toolbox-fe/shared';
import { Asset } from '@my-toolbox-fe/type-asset';

@Component({
  selector: 'mt-upload-form',
  standalone: true,
  imports: [
    CommonModule,
    UploadFormStepsContainerSmartComponent,
    LoaderUiComponent,
    ErrorUiComponent,
    SkeletonUiComponent,
  ],
  templateUrl: './upload-form.smart-component.html',
  styleUrl: './upload-form.smart-component.scss',
})
export class UploadFormSmartComponent implements OnInit {
  protected uploadFormStateService = inject(UploadFormStateService);
  protected uploadFormOptionsSerice = inject(UploadFormOptionsService);
  protected uploadFormEditBuilderService = inject(UploadFormEditBuilderService);

  private route: ActivatedRoute = inject(ActivatedRoute);

  @Input() title = '';

  @Input() mode: FormMode = UPLOAD_MODE_CREATE;

  statusText = '';
  statusMessage = '';
  isLoadingSig = this.uploadFormStateService.isLoadingSig;

  private uploadFormEditBuilder = inject(UploadFormEditBuilderService);

  isAssetAllowedEditable = computed(() => {
    return this.uploadFormEditBuilder.isAssetAllowedEditable();
  });

  async getAsset() {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');

      if (id) {
        this.uploadFormEditBuilderService
          .getAsset(id)
          .then(() => {
            localStorage.removeItem('previousAsset');
          })
          .catch((error) => {
            if (error.status === 404) {
              this.prefillFormLocalStorage(id);
              return;
            }

            this.statusMessage = 'Something went wrong';
            this.statusText = error.statusText;
          })
          .then(() => {
            this.uploadFormStateService.setIsLoading(false);
          });
      }
    });
  }

  async ngOnInit() {
    this.uploadFormStateService.setIsLoading(true);
    this.uploadFormStateService.setIsFormEditable(this.mode === 'edit');
    this.uploadFormStateService.setFormMode(this.mode);

    await this.uploadFormOptionsSerice.getUploadFormOptions().then((res) => {
      const results = res as UploadOptionsFilters;
      this.uploadFormStateService.setFormOptions(results);
      this.uploadFormStateService.setVisibleTypes(results.type);

      if (this.mode === UPLOAD_MODE_EDIT || this.mode === UPLOAD_MODE_COPY) {
        return this.getAsset();
      }

      this.uploadFormStateService.setIsLoading(false);
      return;
    });
  }

  prefillFormLocalStorage(id: string) {
    const previousAsset = localStorage.getItem('previousAsset');

    if (previousAsset) {
      const parsedPreviousAsset: Asset = JSON.parse(previousAsset);

      if (parsedPreviousAsset.id.toString() === id) {
        this.uploadFormEditBuilderService.updateFormValues(parsedPreviousAsset);
      }
    }
  }
}
