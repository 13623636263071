import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROLE_UPLOADER_GLOBAL, ROLE_UPLOADER_LOCAL, RolesService } from '@my-toolbox-fe/feat-roles';

@Injectable({
  providedIn: 'root',
})
export class UploadGuard {
  private rolesService = inject(RolesService);
  private router = inject(Router);

  async canActivate() {
    const hasPermission = await this.rolesService.hasPermissions([
      ROLE_UPLOADER_GLOBAL,
      ROLE_UPLOADER_LOCAL,
    ]);

    return hasPermission ? true : this.router.navigateByUrl('/');
  }
}
