export const validProductCompanyContentTypes: Set<string> = new Set([
  'Product Reference Sheet',
  'Factsheet',
  'Application Data Sheet',
  'Dimension Drawing',
  'Electrical Diagram',
  'Engine Certificate',
  'Flow Diagram',
  'P&ID',
  'Installation Instruction',
  'Instruction Book',
  'Machine Certificate',
  'DataSheet',
  'DataSheet AVR',
  'DataSheet Motor',
  'Datasheet Alternator',
  'Datasheet Engine',
  'Datasheet Circuit Breaker',
  'Datasheet Controller Card',
  'Datasheet Pump Core',
  'Datasheet Battery',
  'Datasheet Inverter',
]);
