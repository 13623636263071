import { environment } from '@my-toolbox-fe/environments/environment';

export function getPageTitle(page: string) {
  if (environment.production) {
    return `MyToolbox - ${page}`;
  }

  let env = '';

  if (environment.environment === 'development') {
    env = 'DEV';
  }

  if (environment.environment === 'staging') {
    env = 'STG';
  }

  return `MyToolbox - ${env} - ${page}`;
}
