import { HttpClient, HttpParams } from '@angular/common/http';
import {
  computed,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from '@angular/core';
import { replaceChar } from '@my-toolbox-fe/data-access-search';
import { environment } from '@my-toolbox-fe/environments/environment';
import { Pagination } from '@my-toolbox-fe/type-pagination';
import {
  UploadHistoryResultType,
  UploadHistoryType,
} from '@my-toolbox-fe/type-upload';
import { firstValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadHistoryService {
  private http = inject(HttpClient);

  uploadHistorySig: WritableSignal<UploadHistoryType[]> = signal([]);
  #pagination = signal<Pagination>({
    currentPage: 0,
    pageSize: 0,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  });
  pagination = computed(this.#pagination);

  isLoading = signal(false);

  async getUploadHistory(currentPage?: number) {
    this.setIsLoading(true);

    let params = new HttpParams();

    if (currentPage) {
      params = params.append('currentPage', currentPage);
    }

    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/assets/uploads`, { params }).pipe(
        map((res) => {
          const { results, pagination } = res as UploadHistoryResultType;
          const mappedRes = results.map((item) => {
            return {
              ...item,
              type: replaceChar(item.type, '_', ' '),
            };
          });

          return {
            results: mappedRes,
            pagination: pagination,
          };
        }),
      ),
    ).then((res) => {
      const data = res as UploadHistoryResultType;
      this.setPagination(data.pagination);
      this.setIsLoading(false);
      this.setUploadHistory(data.results);
    });
  }

  setIsLoading(value: boolean) {
    this.isLoading.set(value);
  }

  setUploadHistory(values: UploadHistoryType[]) {
    this.uploadHistorySig.set(values);
  }

  setPagination(value: Pagination) {
    this.#pagination.set(value);
  }
}
