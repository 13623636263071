import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { Router, NavigationStart } from '@angular/router';
import { ProductVerificationService } from '../product-verification.service';
import { UploadFormStateService } from '../services/upload-form-state.service';
import { TitleGeneration } from '../title-generation.service';
import { UploadFormEditBuilderService } from '../services/edit/upload-form-edit-builder.service';
import { catchError, throwError } from 'rxjs';
import { UserService } from '@my-toolbox-fe/data-access-auth';
import { ROLE_MYTOOLBOX_ADMINISTRATOR } from '@my-toolbox-fe/feat-roles';

export const uploadInterceptor: HttpInterceptorFn = (req, next) => {
  const uploadFormStateService = inject(UploadFormStateService);
  const productVerificationService = inject(ProductVerificationService);
  const titleGenerationService = inject(TitleGeneration);
  const uploadFormEditBuilderService = inject(UploadFormEditBuilderService);
  const userService = inject(UserService);
  const router = inject(Router);

  const userRoles = userService.currentUserSig()?.roles;
  router.events.subscribe((event) => {
    if (event instanceof NavigationStart) {
      uploadFormStateService.clearFormState();
      productVerificationService.clearState();
      titleGenerationService.clearPrevDocumentInformation();
      uploadFormEditBuilderService.clearIsAllowed();
    }
  });

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (userRoles && userRoles.includes(ROLE_MYTOOLBOX_ADMINISTRATOR)) {
        console.error('(ADMIN Log) Upload module error: ', error);
      }

      return throwError(() => error);
    }),
  );
};
