import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFormSmartComponent } from '../../smart-components/upload-form/upload-form.smart-component';

@Component({
  selector: 'mt-upload-page',
  standalone: true,
  imports: [CommonModule, UploadFormSmartComponent],
  templateUrl: './upload-page.ui-component.html',
  styleUrl: './upload-page.ui-component.scss',
})
export class UploadPageUiComponent {}
