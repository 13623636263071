<form #form="ngForm">
  <div class="intro">
    <div>
      <div [class.hasErrors]="productSuggestionHasErrors()" class="validation-info">
        <h5>Product search</h5>
        <p>- Please resolve the invalid options</p>
        <img src="/assets/icons/error-red.svg" alt="icon" />
      </div>
      <p class="info">For more information on Product search, see our FAQ.</p>
    </div>
    <a [href]="emailString">Contact customer support</a>
  </div>

  <mt-dynamic-input
    *field="'serialNumbers'"
    [(ngModel)]="formProductSig().serialNumbers"
    label="Serial number"
    placeholder="Serial number"
    name="serialNumber"
  ></mt-dynamic-input>

  <mt-dynamic-input
    *field="'partNumbers'"
    [(ngModel)]="formProductSig().partNumbers"
    label="Parts number"
    name="partsNumber"
  ></mt-dynamic-input>

  <mt-select
    *field="'productModels'"
    placeholder="Product model"
    label="Product model"
    name="productModel"
    [(ngModel)]="formProductSig().productModels"
    [multiple]="true"
    [options]="formOptionsSig().product_model"
    [clearable]="true"
  ></mt-select>

  <mt-select
    *field="'productSubranges'"
    placeholder="Subrange"
    label="Subrange"
    name="subrange"
    [(ngModel)]="formProductSig().productSubranges"
    [multiple]="true"
    [options]="formOptionsSig().product_subrange"
    [clearable]="true"
  ></mt-select>
  <mt-select
    *field="'productRanges'"
    label="Range"
    placeholder="Range"
    name="range"
    [(ngModel)]="formProductSig().productRanges"
    [multiple]="true"
    [options]="formOptionsSig().product_range"
    [clearable]="true"
  ></mt-select>

  @for (error of productSuggestionSig().errors; track $index) {
    <ul class="error-list">
      @if (error.subject === 'countries' && !error.arguments.length) {
        @if (error.arguments.length) {
          <li>
            You have no matching countries assigned to your profile for the following products:
          </li>
        } @else {
          <li>You have no matching countries assigned to your profile</li>
        }
      } @else {
        <li>Invalid arguments for {{ error.subject }}</li>
      }
      @for (arg of error.arguments; track $index) {
        <li>Invalid: {{ arg }}</li>
      }
    </ul>
  }
  @if (isLoadingSig()) {
    <div class="skeletons">
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
    </div>
  } @else {
    @if (suggestionSummarySig().length) {
      <h5>The following product tags will be added to your document</h5>
      <mt-summary [items]="suggestionSummarySig()"> </mt-summary>
    }

    @if (!hasFormInputValues) {
      <p class="error">Please fill in the form to continue</p>
    }
  }

  <mt-button (submitEvent)="validateProducts()" [disabled]="isLoadingSig()" buttonType="button"
    >Verify products</mt-button
  >
</form>
