import { Component, inject, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import {
  ButtonUiComponent,
  InputUiComponent,
  TextareaUiComponent,
} from '@my-toolbox-fe/ui-elements';

import {
  TitleGeneration,
  UploadForm,
  UploadFormDataBuilderService,
  UploadFormEditBuilderService,
  UploadFormStateService,
} from '@my-toolbox-fe/data-access-upload';
import { UploadTitleLabelUiComponent } from '../../../ui-components/upload-title-label/upload-title-label.ui-component';

import { getFileExtension, isFile } from '@my-toolbox-fe/shared';

@Component({
  selector: 'mt-upload-form-steps-title-generation',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputUiComponent,
    TextareaUiComponent,
    UploadTitleLabelUiComponent,
    ButtonUiComponent,
  ],
  templateUrl: './upload-form-steps-title-generation.smart-component.html',
  styleUrl: './upload-form-steps-title-generation.smart-component.scss',
})
export class UploadFormStepsTitleGenerationSmartComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm | null = null;

  protected titleGenerationService = inject(TitleGeneration);

  protected uploadFormEditBuilderService = inject(UploadFormEditBuilderService);

  assetPreviewSig = this.uploadFormEditBuilderService.assetPreviewSig;

  uploadFormDataBuilderService = inject(UploadFormDataBuilderService);

  protected uploadFormStateService = inject(UploadFormStateService);

  formPropertyValuesSig: WritableSignal<UploadForm> =
    this.uploadFormDataBuilderService.formPropertyValuesSig;

  formFileSig = this.uploadFormStateService.formFileSig;

  isFormEditableSig: WritableSignal<boolean> = this.uploadFormStateService.isFormEditableSig;

  hasFormValuesBeenAlteredSig = this.uploadFormDataBuilderService.hasFormValuesBeenAlteredSig;

  prevDocumentInformationSig = this.titleGenerationService.prevDocumentInformationSig;

  serviceBulletinNumber = '';
  documentInformation = '';
  contentType = '';
  languages = '';
  productCompanies = '';
  documentNumber = '';
  generatedFileTitle = '';
  generatedFileName = '';

  fileExtension = '';

  documentInformationInput = '';
  documentInformationCorrection = '';

  documentInformationCorrectionLength = 0;

  showCustomInput = false;

  isInputRequired = false;

  showReset = true;

  generatedFileString = '';

  ngOnInit(): void {
    this.initValues();
  }

  initValues() {
    this.setFileExtension();
    this.generateStrings();
    this.documentInformationCorrectionLength = this.calculateMaxLength();

    if (this.documentInformation.length <= this.documentInformationCorrectionLength) {
      if (this.hasFormValuesBeenAlteredSig() === 'unchanged') {
        this.documentInformationCorrection = this.prevDocumentInformationSig();
      } else {
        this.documentInformationCorrection = this.documentInformation;
      }
      this.documentInformationInput = this.documentInformationCorrection;
      this.titleGenerationService.setDocumentInformation(this.documentInformationCorrection);
    } else {
      this.showReset = false;
    }

    this.generatedFileString = this.buildFileString();
    this.generatedFileName = this.buildFileNameString();

    this.isInputRequired = this.titleGenerationService.isInputMandatory(
      this.formPropertyValuesSig().productRelated,
      this.generatedFileName.length,
    );

    this.uploadFormDataBuilderService.setFileTitle(this.generatedFileString);
    this.uploadFormDataBuilderService.setFileName(this.generatedFileName);
  }

  calculateMaxLength() {
    return this.titleGenerationService.calculateInputLength([
      this.contentType,
      this.languages,
      this.productCompanies,
      this.documentNumber,
      this.fileExtension,
    ]);
  }

  setFileExtension() {
    if (this.isFormEditableSig()) {
      return (this.fileExtension = this.assetPreviewSig().extension);
    }

    const uploadFile = this.formFileSig().uploadFile;

    if (isFile(uploadFile)) {
      this.fileExtension = getFileExtension(uploadFile);
    }

    return this.fileExtension;
  }

  generateStrings() {
    this.serviceBulletinNumber = this.titleGenerationService.generateServiceBulletinNumber(
      this.formPropertyValuesSig().contentType,
      this.formPropertyValuesSig().serviceBulletinNumber,
    );

    this.documentInformation = this.titleGenerationService.generateDocumentInformation(
      this.formPropertyValuesSig().productRelated,
      {
        productModels: this.formPropertyValuesSig().productModels,
        productSubranges: this.formPropertyValuesSig().productSubranges,
        productRanges: this.formPropertyValuesSig().productRanges,
        productNames: this.formPropertyValuesSig().productNames,
        serialNumbers: this.formPropertyValuesSig().serialNumbers,
        partNumbers: this.formPropertyValuesSig().partNumbers,
      },
    );

    this.contentType = this.titleGenerationService.generateDocumentType(
      this.formPropertyValuesSig().contentType,
      this.formPropertyValuesSig().serviceBulletinClassification,
    );

    this.languages = this.titleGenerationService.generateLanguages(
      this.formPropertyValuesSig().languages,
    );

    this.productCompanies = this.titleGenerationService.generateOrganizationProductCompany(
      this.formPropertyValuesSig().contentType.value,
      this.formPropertyValuesSig().productCompanies,
    );

    this.documentNumber = this.titleGenerationService.generateDocumentNumber(
      this.formPropertyValuesSig().contentType.value,
      this.formPropertyValuesSig().documentNumber,
    );
  }

  buildFileNameString() {
    return `${this.buildFileString()}.${this.fileExtension}`;
  }

  buildFileString() {
    return this.titleGenerationService.buildString([
      this.serviceBulletinNumber,
      this.documentInformationCorrection,
      this.contentType,
      this.languages,
      this.productCompanies,
      this.documentNumber,
    ]);
  }

  onProductInformationEdit() {
    if (this.documentInformationInput === '') {
      this.uploadFormDataBuilderService.setFileTitle(this.generatedFileString);
      this.uploadFormDataBuilderService.setFileName(this.generatedFileName);

      this.documentInformationCorrection = '';

      return;
    }

    this.documentInformationCorrection = this.documentInformationInput;
    this.titleGenerationService.setDocumentInformation(this.documentInformationCorrection);
    const title = this.buildFileString();

    const name = this.buildFileNameString();

    this.uploadFormDataBuilderService.setFileTitle(title);
    this.uploadFormDataBuilderService.setFileName(name);
  }

  onReset() {
    this.documentInformationInput = this.documentInformation;
    this.documentInformationCorrection = this.documentInformation;
  }
}
