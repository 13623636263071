<form #form="ngForm">
  <mt-select
    *field="'productCompanies'"
    name="productCompany"
    placeholder="Product company"
    label="Product company"
    [(ngModel)]="formOrganizationSig().productCompanies"
    [options]="formOptionsSig().product_company"
    [multiple]="true"
  ></mt-select>
  <mt-select
    *field="'brands'"
    name="brand"
    placeholder="Brand"
    label="Brand"
    [required]="true"
    [(ngModel)]="formOrganizationSig().brands[0]"
    [options]="formOptionsSig().brand"
    [errorMessage]="brandErrorMessage"
  ></mt-select>

  <mt-select
    *field="'businessLines'"
    name="businessLine"
    placeholder="Business line"
    label="Business line"
    [(ngModel)]="formOrganizationSig().businessLines"
    [options]="formOptionsSig().business_line"
    [multiple]="true"
  ></mt-select>
  <mt-select
    *field="'countries'"
    name="country"
    placeholder="Country"
    label="Country"
    [required]="true"
    [(ngModel)]="formOrganizationSig().countries"
    [options]="formOptionsSig().country"
    [multiple]="true"
    [clearable]="true"
    [hasSelectAll]="true"
  ></mt-select>
  @if (formSig().visibility === QB_VISIBLITY_EXTERNAL) {
    <div class="restricted">
      <mt-checkbox
        class="restricted"
        (input)="onToggleRestricted()"
        name="restricted"
        [(ngModel)]="isRestricted"
      >
        Restricted document
      </mt-checkbox>
      <mt-tooltip [content]="tooltipContent"></mt-tooltip>
    </div>
  }
  @if (isRestricted) {
    <mt-select
      [(ngModel)]="formOrganizationSig().restrictedRoles"
      [options]="formOptionsSig().restricted_role"
      name="restrictedRoles"
      placeholder="Roles"
      label="Roles"
      [multiple]="true"
    ></mt-select>
  }
</form>
