import { inject, Injectable, WritableSignal } from '@angular/core';
import { Filter } from '@my-toolbox-fe/type-filter';
import { BehaviorSubject } from 'rxjs';
import { UploadFormStateService } from './upload-form-state.service';
import {
  FormSig,
  Organization,
  ProductInformation,
  UploadOptionsFilters,
} from '@my-toolbox-fe/type-upload';

@Injectable({ providedIn: 'root' })
export class UploadFormAllowedFieldsService {
  protected uploadFormStateService = inject(UploadFormStateService);

  private formSig: WritableSignal<FormSig> = this.uploadFormStateService.formSig;
  private formOrganizationSig: WritableSignal<Organization> =
    this.uploadFormStateService.formOrganizationSig;
  private formProductSig: WritableSignal<ProductInformation> =
    this.uploadFormStateService.formProductSig;
  private formOptionsSig: WritableSignal<UploadOptionsFilters> =
    this.uploadFormStateService.formOptionsSig;
  private allowedFieldsSigSource = new BehaviorSubject<string[]>([]);
  allowedFieldsSig$ = this.allowedFieldsSigSource.asObservable();

  allowedFields: string[] = [];

  updateAllowedFields(type: string): void {
    const filteredItem = this.formOptionsSig().type.filter((item: Filter) => item.value === type);

    this.allowedFields = filteredItem[0].uploadFields ?? [];

    if (this.allowedFields) {
      this.allowedFieldsSigSource.next(this.allowedFields);
    }
  }

  updateAllowedFieldsValues() {
    this.uploadFormStateService.setForm({
      ...this.formSig(),
      visibility: this.isFieldAllowed('visibility') ? this.formSig().visibility : '',
      languages: this.isFieldAllowed('languages') ? this.formSig().languages : [],
      category: this.isFieldAllowed('category') ? this.formSig().category : '',
      contentType: this.isFieldAllowed('contentType') ? this.formSig().contentType : '',
      description: this.isFieldAllowed('description') ? this.formSig().description : '',
      externalUrl: this.isFieldAllowed('externalUrl') ? this.formSig().externalUrl : null,
      documentNumber: this.isFieldAllowed('documentNumber') ? this.formSig().documentNumber : null,
      serviceBulletinClassification: this.isFieldAllowed('serviceBulletinClassification')
        ? this.formSig().serviceBulletinClassification
        : null,
      serviceBulletinNumber: this.isFieldAllowed('serviceBulletinNumber')
        ? this.formSig().serviceBulletinNumber
        : null,
      measures: this.isFieldAllowed('measures') ? this.formSig().measures : [],
      validFrom: this.isFieldAllowed('validFrom') ? this.formSig().validFrom : null,
      validTo: this.isFieldAllowed('validTo') ? this.formSig().validTo : null,
      productRelated: this.isFieldAllowed('productRelated') ? this.formSig().productRelated : false,
      syncWithShowPad: this.isFieldAllowed('syncWithShowPad')
        ? this.formSig().syncWithShowPad
        : false,
    });

    this.uploadFormStateService.setOrganization({
      ...this.formOrganizationSig(),
      productCompanies: this.isFieldAllowed('productCompanies')
        ? this.formOrganizationSig().productCompanies
        : [],
      brands: this.isFieldAllowed('brands') ? this.formOrganizationSig().brands : [],
      businessLines: this.isFieldAllowed('businessLines')
        ? this.formOrganizationSig().businessLines
        : [],
      countries: this.isFieldAllowed('countries') ? this.formOrganizationSig().countries : [],
      restrictedRoles: this.isFieldAllowed('restrictedRoles')
        ? this.formOrganizationSig().restrictedRoles
        : [],
    });
  }

  private isFieldAllowed(value: string) {
    return this.allowedFields.includes(value);
  }
}
