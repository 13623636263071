<mt-layout title="Uploaded files / File history">
  <div actions>
    <mt-link icon="/assets/icons/upload-white.svg" link="/upload" linkStyle="button">
      Upload new file
    </mt-link>
  </div>
  @if (isLoadingSig()) {
    <div class="skeletons">
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
      <mt-skeleton></mt-skeleton>
    </div>
  } @else {
    <mt-table [columnData]="colData" [rowData]="uploadHistorySig()">
      <ng-template #rows let-row>
        <td>
          <a [routerLink]="['/asset', row.uid]">{{ row.title }}</a>
        </td>
        <td>{{ row.type }}</td>
        <td>{{ row.uploadDate }}</td>
      </ng-template>
    </mt-table>
    <mt-pagination
      (pageChange)="onPageChange($event)"
      [pagination]="paginationSig()"
    ></mt-pagination>
  }
</mt-layout>
