<form #form="ngForm">
  <mt-select
    name="category"
    [options]="formOptionsSig().category"
    [(ngModel)]="formSig().category"
    placeholder="Category"
    label="Category"
    [required]="true"
    [disabled]="isCategoryDisabled"
    (selectEvent)="onSelectCategoryEvent($event)"
  ></mt-select>
  <mt-select
    name="type"
    [options]="visibleTypesSig()"
    [(ngModel)]="formSig().contentType"
    placeholder="Content type"
    label="Content type"
    [required]="true"
    (selectEvent)="onSelectTypeEvent($event)"
  ></mt-select>
</form>
