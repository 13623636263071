<div class="container">
  @if (isLoadingSig()) {
    <div class="form">
      <div class="skeletons">
        <mt-skeleton></mt-skeleton>
        <mt-skeleton></mt-skeleton>
        <mt-skeleton></mt-skeleton>
        <mt-skeleton></mt-skeleton>
        <mt-skeleton></mt-skeleton>
      </div>
    </div>
  } @else if (statusText) {
    <mt-error [message]="statusMessage" [title]="statusText"></mt-error>
  } @else if (!isAssetAllowedEditable() && mode === 'edit') {
    <mt-error message="You are not allowed to edit this asset" title="Unauthorized"></mt-error>
  } @else {
    <h1>{{ title }}</h1>
    <ng-content></ng-content>

    <div class="form">
      <mt-upload-form-steps-container></mt-upload-form-steps-container>
    </div>
  }
</div>
