import { Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  CheckboxUiComponent,
  DatePickerUiComponent,
  InputUiComponent,
  SelectUiComponent,
  TextareaUiComponent,
  WarningUiComponent,
} from '@my-toolbox-fe/ui-elements';
import { FormsModule, NgForm } from '@angular/forms';

import {
  ProductVerificationService,
  ShowFieldDirective,
  UploadFormStateService,
} from '@my-toolbox-fe/data-access-upload';
import {
  QB_VISIBLITY_EXTERNAL,
  TYPE_COMPETITOR_COMPERISON,
  TYPE_PRICE_LIST,
  TYPE_SERVICE_BULLETIN,
} from '@my-toolbox-fe/shared';

@Component({
  selector: 'mt-upload-form-steps-file-details',
  standalone: true,
  imports: [
    CommonModule,
    SelectUiComponent,
    InputUiComponent,
    FormsModule,
    TextareaUiComponent,
    DatePickerUiComponent,
    InputUiComponent,
    CheckboxUiComponent,
    ShowFieldDirective,
    WarningUiComponent,
  ],
  templateUrl: './upload-form-steps-file-details.smart-component.html',
  styleUrl: './upload-form-steps-file-details.smart-component.scss',
  providers: [DatePipe],
})
export class UploadFormStepsFileDetailsSmartComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm | null = null;

  currentDate: string | null;
  isDateRangeValid = true;

  protected productVerificationService = inject(ProductVerificationService);

  protected uploadFormStateService = inject(UploadFormStateService);

  formSig = this.uploadFormStateService.formSig;
  formOptionsSig = this.uploadFormStateService.formOptionsSig;

  isProductVerificationLoading = this.productVerificationService.isLoading;

  showVisiblityWarning = signal(false);

  constructor(private datePipe: DatePipe) {
    this.currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
  }
  TYPE_SERVICE_BULLETIN = TYPE_SERVICE_BULLETIN;

  visiblityWarningTypes = [TYPE_COMPETITOR_COMPERISON, TYPE_PRICE_LIST, TYPE_SERVICE_BULLETIN];

  onVisibilitySelect() {
    this.setShowVisiblityWarning();
  }

  setShowVisiblityWarning() {
    this.showVisiblityWarning.set(
      this.visiblityWarningTypes.includes(this.formSig().contentType) &&
        this.formSig().visibility === QB_VISIBLITY_EXTERNAL,
    );
  }

  ngOnInit(): void {
    this.setShowVisiblityWarning();
  }

  validateDateRange() {
    const validFrom = this.formSig().validFrom;
    const validTo = this.formSig().validTo;

    if (validFrom && validTo) {
      this.isDateRangeValid = validFrom <= validTo;

      if (!this.isDateRangeValid) {
        this.ngForm?.form.controls['validTo'].setErrors({ incorrect: true });
        this.ngForm?.form.controls['validFrom'].setErrors({ incorrect: true });
        return;
      }
    }

    this.ngForm?.form.controls['validTo'].setErrors(null);
    this.ngForm?.form.controls['validFrom'].setErrors(null);
    this.isDateRangeValid = true;
  }

  clearProductRelated() {
    this.productVerificationService.clearState();
    this.uploadFormStateService.resetProductVerificationSuggestion();
  }
}
