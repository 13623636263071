import { Injectable, signal } from '@angular/core';
import { TYPE_ORGINIAL_SERVICE_BULLETING } from '@my-toolbox-fe/shared';
import { Filter } from '@my-toolbox-fe/type-filter';

import {
  ProductInformationSelected,
  validProductCompanyContentTypes,
} from '@my-toolbox-fe/type-upload';

@Injectable({ providedIn: 'root' })
export class TitleGeneration {
  maxStringLength = 150;

  prevDocumentInformationSig = signal('');

  setDocumentInformation(value: string) {
    this.prevDocumentInformationSig.set(value);
  }

  clearPrevDocumentInformation() {
    this.prevDocumentInformationSig.set('');
  }

  isLengthValid(length: number) {
    return length < this.maxStringLength;
  }

  isInputMandatory(isProductRelated: boolean, stringLength: number): boolean {
    if (!isProductRelated) {
      return true;
    }

    if (this.isLengthValid(stringLength)) {
      return true;
    }

    return false;
  }

  joinAndMapValues(values: Filter[]) {
    const mappedValues = values.map((item) => {
      return item.name;
    });

    const joinedValues = mappedValues.join(' ');

    return joinedValues;
  }

  joinValues(values: string[]) {
    return values.join(' ');
  }

  calculateInputLength(values: string[]) {
    let length = 0;

    values.forEach((val) => {
      length += val.length;
    });

    return this.maxStringLength - length;
  }

  generateDocumentInformation(
    productRelated: boolean,
    productInformation: ProductInformationSelected,
  ) {
    if (!productRelated) {
      return '';
    }

    let documentInformation = '';

    const { productModels, productSubranges, productRanges } = productInformation;

    if (productModels && productModels.length) {
      documentInformation += this.joinAndMapValues(productModels);
    } else if (productSubranges && productSubranges.length) {
      documentInformation += this.joinAndMapValues(productSubranges);
    } else if (productRanges && productRanges.length) {
      documentInformation += this.joinAndMapValues(productRanges);
    }

    return documentInformation;
  }

  generateDocumentType(contentType: Filter, serviceBulletinClassification: Filter | null) {
    let title = '';
    if (contentType.value === TYPE_ORGINIAL_SERVICE_BULLETING && serviceBulletinClassification) {
      return (title += `Service Bulletin ${serviceBulletinClassification.value}`);
    }

    return (title += `${contentType.name}`);
  }

  generateServiceBulletinNumber(contentType: Filter, serviceBulletinNumber: string | null) {
    if (contentType.value !== TYPE_ORGINIAL_SERVICE_BULLETING) {
      return '';
    }

    if (!serviceBulletinNumber) {
      return '';
    }

    return serviceBulletinNumber;
  }

  generateLanguages(languages: Filter[]) {
    let lan = '';

    if (!languages.length) {
      return '';
    }

    if (languages.length > 1) {
      return '';
    }

    languages.forEach((item) => {
      lan += `${item.value}`;
    });

    return lan;
  }

  generateOrganizationProductCompany(contentType: string, productCompanies: Filter[]) {
    if (!validProductCompanyContentTypes.has(contentType)) {
      return '';
    }

    if (!productCompanies) {
      return '';
    }

    if (productCompanies?.length > 1) {
      return '';
    }

    const pc = this.joinAndMapValues(productCompanies);

    if (pc) return pc;

    return '';
  }

  generateDocumentNumber(contentType: string, documentNumber: string | null) {
    if (documentNumber) {
      return documentNumber;
    }
    return '';
  }

  buildString(parts: string[]) {
    return parts
      .map((part) => part.trim())
      .filter((part) => part.length > 0)
      .join(' ');
  }
}
