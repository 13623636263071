<mt-dialog title="Upload file">
  @if (isFormEditableSig()) {
    <p>Are you sure you want to edit this file?</p>
  } @else {
    <p>Are you sure you want to upload this file?</p>
  }
  <div class="buttons">
    <mt-button (submitEvent)="confirm()" class="save">{{
      isFormEditableSig() ? 'Save changes' : 'Save upload'
    }}</mt-button
    ><mt-button (submitEvent)="close()" buttonStyle="secondary" class="close">Close</mt-button>
  </div>

  @if (formModeSig() === 'create' || formModeSig() === 'copy') {
    <mt-checkbox [(ngModel)]="similarUpload">Upload another document with similar tags</mt-checkbox>
  }
</mt-dialog>
