import {
  Directive,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadFormAllowedFieldsService } from '../services/upload-form-allowed-fields.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[field]',
  standalone: true,
})
export class ShowFieldDirective implements OnInit, OnDestroy {
  @Input() field = '';

  private hasView = false;
  private viewContainerRef = inject(ViewContainerRef);
  private templateRef = inject(TemplateRef<any>);
  private subscription: Subscription = new Subscription();

  protected uploadFormAllowedFieldsService = inject(UploadFormAllowedFieldsService);

  ngOnInit() {
    this.subscription = this.uploadFormAllowedFieldsService.allowedFieldsSig$.subscribe(
      (allowedFields) => {
        this.updateView(allowedFields);
      },
    );
  }

  private updateView(allowedFields: string[]) {
    if (!allowedFields.length) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
      return;
    }

    const fieldIsAllowed = allowedFields.includes(this.field);

    if (fieldIsAllowed && !this.hasView) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!fieldIsAllowed && this.hasView) {
      this.viewContainerRef.clear();
      this.hasView = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
