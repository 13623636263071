import { UploadOptionsFilters } from '@my-toolbox-fe/type-upload';

export const mockUploadOptions: UploadOptionsFilters = {
  asset_audience: [
    { code: 'AA01', value: 'value1', name: 'Asset Audience 1' },
    { code: 'AA02', value: 'value2', name: 'Asset Audience 2' },
  ],
  brand: [
    { code: 'BR01', value: 'value1', name: 'Brand 1' },
    { code: 'BR02', value: 'value2', name: 'Brand 2' },
  ],
  business_line: [
    { code: 'BL01', value: 'value1', name: 'Business Line 1' },
    { code: 'BL02', value: 'value2', name: 'Business Line 2' },
  ],
  category: [
    { code: 'CAT01', value: 'value1', name: 'Category 1' },
    { code: 'CAT02', value: 'value2', name: 'Category 2' },
  ],
  country: [
    { code: 'CO01', value: 'value1', name: 'Country 1' },
    { code: 'CO02', value: 'value2', name: 'Country 2' },
  ],
  language: [
    { code: 'LA01', value: 'value1', name: 'Language 1' },
    { code: 'LA02', value: 'value2', name: 'Language 2' },
  ],
  measure: [
    { code: 'ME01', value: 'value1', name: 'Measure 1' },
    { code: 'ME02', value: 'value2', name: 'Measure 2' },
  ],
  product_model: [
    { code: 'PM01', value: 'value1', name: 'Product Model 1' },
    { code: 'PM02', value: 'value2', name: 'Product Model 2' },
  ],
  product_company: [
    { code: 'PC01', value: 'value1', name: 'Product Company 1' },
    { code: 'PC02', value: 'value2', name: 'Product Company 2' },
  ],
  product_range: [
    { code: 'PR01', value: 'value1', name: 'Product Range 1' },
    { code: 'PR02', value: 'value2', name: 'Product Range 2' },
  ],
  product_subrange: [
    { code: 'PS01', value: 'value1', name: 'Product Subrange 1' },
    { code: 'PS02', value: 'value2', name: 'Product Subrange 2' },
  ],
  sb_classification: [
    { code: 'SB01', value: 'value1', name: 'SB Classification 1' },
    { code: 'SB02', value: 'value2', name: 'SB Classification 2' },
  ],
  type: [
    { code: 'TY01', value: 'value1', name: 'Type 1' },
    { code: 'TY02', value: 'value2', name: 'Type 2' },
  ],
  visibility: [
    { code: 'VI01', value: 'value1', name: 'Visibility 1' },
    { code: 'VI02', value: 'value2', name: 'Visibility 2' },
  ],
  restricted_role: [
    { code: 'RR01', value: 'value1', name: 'Restricted Role 1' },
    { code: 'RR02', value: 'value2', name: 'Restricted Role 2' },
  ],
};
