import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { replaceSpaceWithUnderscore } from '@my-toolbox-fe/data-access-search';
import { environment } from '@my-toolbox-fe/environments/environment';
import { Filter } from '@my-toolbox-fe/type-filter';
import { codesLanguages, codesBrands, codesCountries } from '@my-toolbox-fe/type-search';
import { UploadFormPrefill, UploadOptionsFilters } from '@my-toolbox-fe/type-upload';
import { catchError, firstValueFrom, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadFormOptionsService {
  private http = inject(HttpClient);

  formFileDetailsCodes =
    'asset_audience,visibility,language,category,type,sb_classification,measure,product_subrange,product_range,brand,business_line,country,product_company,product_model,restricted_role';

  getCorrectName(itemValue: Filter) {
    if (itemValue.code === 'language') {
      return codesLanguages[itemValue.value];
    }

    if (itemValue.code === 'brand') {
      return codesBrands[itemValue.value];
    }

    if (itemValue.code === 'country') {
      return codesCountries[itemValue.value];
    }

    return itemValue.name;
  }

  sort(a?: any, b?: any) {
    const nameA = a.name || '';
    const nameB = b.name || '';
    return nameA.localeCompare(nameB);
  }

  async getUploadFormOptions() {
    return await firstValueFrom(
      this.http
        .get(`${environment.baseUrl}/properties?codes=${this.formFileDetailsCodes}&context=UPLOAD`)
        .pipe(
          map((res) => {
            const results = res as UploadFormPrefill[];
            return results.reduce((acc: any, item) => {
              acc[item.code] = item.values
                .map((itemValue) => {
                  return {
                    code: itemValue.code,
                    name: this.getCorrectName(itemValue),
                    value: replaceSpaceWithUnderscore(itemValue.value),
                    categoryId: itemValue.categoryId ?? null,
                    uploadFields: itemValue.uploadFields ?? null,
                    defaultVisibility: itemValue.defaultVisibility ?? null,
                  };
                })
                .sort(this.sort);
              return acc;
            }, {});
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          }),
        ),
    ).then((res) => {
      const results = <UploadOptionsFilters>res;
      return results;
    });
  }
}
