import { Injectable } from '@angular/core';
import { Filter } from '@my-toolbox-fe/type-filter';

@Injectable({ providedIn: 'root' })
export class UploadFormTypesService {
  getFilteredCategoryContentType(categoryId: string, types: Filter[]) {
    const filteredTypes: Filter[] = types.filter(
      (item: Filter) => item.categoryId === Number(categoryId),
    );

    return filteredTypes;
  }

  getCategoryMatchingType(categoryId: string, categories: Filter[]) {
    if (!categoryId) {
      return;
    }

    const category = categories.filter((item: Filter) => {
      return item.value === categoryId;
    });

    return category;
  }
}
