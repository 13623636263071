import { Route } from '@angular/router';

import { UploadGuard } from './guards/upload.guard';
import { UploadHistorySmartComponent } from './smart-components/upload-history/upload-history.smart-component';
import { UploadPageUiComponent } from './ui-components/upload-page/upload-page.ui-component';
import { getPageTitle } from '@my-toolbox-fe/shared';

export const featUploadRoutes: Route[] = [
  {
    path: '',
    component: UploadPageUiComponent,
    canActivate: [UploadGuard],
  },
  {
    path: 'history',
    title: getPageTitle('Upload - history'),
    component: UploadHistorySmartComponent,
    canActivate: [UploadGuard],
  },
];
