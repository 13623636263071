import { Component, computed, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AssetPreviewUiComponent,
  ButtonUiComponent,
  DialogService,
  DialogUiComponent,
  InputUiComponent,
  LoaderUiComponent,
  SelectUiComponent,
  StepsUiComponent,
  TextareaUiComponent,
  UploadUiComponent,
} from '@my-toolbox-fe/ui-elements';

import { FormsModule, NgForm } from '@angular/forms';

import { UploadFormDialogSmartComponent } from '../../upload-form-dialog/upload-form-dialog.smart-component';
import { Router } from '@angular/router';

import {
  ProductVerificationService,
  UploadFormDataBuilderService,
  UploadFormEditBuilderService,
  UploadFormStateService,
} from '@my-toolbox-fe/data-access-upload';
import { Subscription } from 'rxjs';
import { UploadFormStepsFileDetailsSmartComponent } from '../upload-form-steps-file-details/upload-form-steps-file-details.smart-component';
import { UploadFormStepsProductInformationSmartComponent } from '../upload-form-steps-product-information/upload-form-steps-product-information.smart-component';
import { UploadFormStepsOrganizationSmartComponent } from '../upload-form-steps-organization/upload-form-steps-organization.smart-component';
import { UploadFormStepsTitleGenerationSmartComponent } from '../upload-form-steps-title-generation/upload-form-steps-title-generation.smart-component';
import { UserService } from '@my-toolbox-fe/data-access-auth';

import { UploadFormStepsCategorySmartComponent } from '../upload-form-steps-category/upload-form-steps-category.smart-component';
import { UploadFormStepsSummaryUiComponent } from '../upload-form-steps-summary/upload-form-steps-summary.ui-component';

@Component({
  selector: 'mt-upload-form-steps-container',
  standalone: true,
  templateUrl: './upload-form-steps-container.smart-component.html',
  styleUrl: './upload-form-steps-container.smart-component.scss',
  imports: [
    CommonModule,
    ButtonUiComponent,
    UploadUiComponent,
    StepsUiComponent,
    InputUiComponent,
    SelectUiComponent,
    StepsUiComponent,
    FormsModule,
    DialogUiComponent,
    LoaderUiComponent,
    TextareaUiComponent,
    UploadFormStepsCategorySmartComponent,
    UploadFormStepsFileDetailsSmartComponent,
    UploadFormStepsProductInformationSmartComponent,
    UploadFormStepsOrganizationSmartComponent,
    UploadUiComponent,
    UploadFormStepsTitleGenerationSmartComponent,
    UploadFormStepsSummaryUiComponent,
    AssetPreviewUiComponent,
  ],
})
export class UploadFormStepsContainerSmartComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm | null = null;

  @ViewChild(UploadFormStepsFileDetailsSmartComponent)
  set formFileDetails(component: UploadFormStepsFileDetailsSmartComponent | undefined) {
    if (component) {
      this.currentStepForm = component;
    }
  }

  @ViewChild(UploadFormStepsCategorySmartComponent)
  set formCategory(component: UploadFormStepsCategorySmartComponent | undefined) {
    if (component) {
      this.currentStepForm = component;
    }
  }

  @ViewChild(UploadFormStepsProductInformationSmartComponent)
  formProductInformation: UploadFormStepsProductInformationSmartComponent | undefined;

  @ViewChild(UploadFormStepsOrganizationSmartComponent)
  set formOrganization(component: UploadFormStepsOrganizationSmartComponent | undefined) {
    if (component) {
      this.currentStepForm = component;
    }
  }

  @ViewChild(UploadFormStepsTitleGenerationSmartComponent)
  set formTitleGeneration(component: UploadFormStepsTitleGenerationSmartComponent | undefined) {
    if (component) {
      this.currentStepForm = component;
    }
  }

  private router = inject(Router);

  protected dialogService = inject(DialogService);
  protected userService = inject(UserService);

  protected productVerficationService = inject(ProductVerificationService);

  protected uploadFormStateService = inject(UploadFormStateService);

  protected uploadFormDataBuilderService = inject(UploadFormDataBuilderService);

  protected uploadFormEditBuilderService = inject(UploadFormEditBuilderService);

  assetPreviewSig = this.uploadFormEditBuilderService.assetPreviewSig;

  formFunction = this.uploadFormStateService.formFunction;

  formSig = this.uploadFormStateService.formSig;

  formFileSig = this.uploadFormStateService.formFileSig;
  isProductVerificationLoading = this.productVerficationService.isLoading;

  isLoadingSig = this.uploadFormStateService.isLoadingSig;

  isFormEditableSig = this.uploadFormStateService.isFormEditableSig;

  isNextStepDisabled = this.uploadFormStateService.isNextStepDisabled;

  currentStep = computed(() => {
    return this.uploadFormStateService.currentStep();
  });
  currentStepForm: any;

  subscription: Subscription = new Subscription();

  steps = [
    { title: 'Category' },
    { title: 'File details' },
    { title: 'Organization' },
    { title: 'Title generation' },
    { title: 'Upload summary' },
  ];

  ngOnInit() {
    this.subscription = this.uploadFormStateService.isFormCompleted.subscribe((isFormCompleted) => {
      if (isFormCompleted && this.ngForm) {
        if (this.ngForm.form.controls['file']) {
          this.ngForm.form.controls['file'].reset();
          this.uploadFormStateService.setIsFormCompleted(false);
        }
      }
    });
  }

  openDialog() {
    this.dialogService.open(UploadFormDialogSmartComponent);
  }

  onSubmit() {
    if (!this.isFormEditableSig()) {
      this.ngForm?.form.markAllAsTouched();
    }

    if (this.currentStepForm.ngForm) {
      this.currentStepForm.ngForm?.form.markAllAsTouched();

      if (!this.currentStepForm.ngForm?.form.valid) {
        return;
      }
    }

    if (!this.ngForm?.form.valid && !this.isFormEditableSig()) {
      return;
    }

    if (this.currentStep() !== this.steps.length - 1) {
      this.nextStep();

      if (this.currentStep() === this.steps.length - 2) {
        this.uploadFormDataBuilderService.convertFormToPropertyValues();
      }

      return;
    }

    return this.openDialog();
  }

  nextStep() {
    this.uploadFormStateService.setCurrentStep(this.currentStep() + 1);
  }

  onCancel() {
    if (this.currentStep() === 0) {
      this.router.navigateByUrl('/');
      return;
    }
    this.uploadFormStateService.setCurrentStep(this.currentStep() - 1);
  }

  buttonPrevText() {
    return this.currentStep() === 0 ? 'Cancel' : 'Previous step';
  }

  buttonNextText() {
    if (this.isFormEditableSig() && this.currentStep() === this.steps.length - 1) {
      return 'Save changes';
    }
    return this.currentStep() === this.steps.length - 1 ? 'Upload file' : 'Next step';
  }

  showFormStep(formStep: number) {
    this.uploadFormStateService.setCurrentStep(formStep);
  }
}
