import { Component, inject, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonUiComponent,
  LinkUiComponent,
  PaginationUiComponent,
  TableUiComponent,
  LayoutUiComponent,
  SkeletonUiComponent,
} from '@my-toolbox-fe/ui-elements';
import { UploadHistoryService } from '@my-toolbox-fe/data-access-upload';
import { UploadHistoryType } from '@my-toolbox-fe/type-upload';
import { Pagination } from '@my-toolbox-fe/type-pagination';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'mt-upload-history',
  standalone: true,
  imports: [
    CommonModule,
    ButtonUiComponent,
    TableUiComponent,
    LinkUiComponent,
    PaginationUiComponent,
    LayoutUiComponent,
    RouterLink,
    SkeletonUiComponent,
  ],
  templateUrl: './upload-history.smart-component.html',
  styleUrl: './upload-history.smart-component.scss',
})
export class UploadHistorySmartComponent implements OnInit {
  protected uploadHistoryService = inject(UploadHistoryService);

  isLoadingSig = signal(false);

  uploadHistorySig: WritableSignal<UploadHistoryType[]> =
    this.uploadHistoryService.uploadHistorySig;
  paginationSig: Signal<Pagination> = this.uploadHistoryService.pagination;

  async ngOnInit() {
    this.getUploadHistory();
  }

  async getUploadHistory() {
    this.isLoadingSig.set(true);
    await this.uploadHistoryService.getUploadHistory().finally(() => {
      this.isLoadingSig.set(false);
    });
  }

  colData = [
    { key: 'title', label: 'Title' },
    { key: 'type', label: 'Type' },
    { key: 'uploadDate', label: 'Uploaded on' },
  ];

  async onPageChange(page: number) {
    this.paginationSig().currentPage = page;
    this.uploadHistoryService.getUploadHistory(page);
  }
}
