import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { UploadFormStateService } from './upload-form-state.service';
import {
  filterItems,
  filterItemsLanguage,
  findOptionalPropertyValue,
  findPropertyValue,
} from '@my-toolbox-fe/shared';
import { Filter } from '@my-toolbox-fe/type-filter';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@my-toolbox-fe/environments/environment';
import { catchError, throwError } from 'rxjs';
import { AlertService } from '@my-toolbox-fe/ui-elements';
import * as _ from 'lodash';
import { Asset } from '@my-toolbox-fe/type-asset';

export type UploadForm = {
  title: string;
  realFileName: string;
  audience: Filter;
  brands: Filter[];
  businessLines: Filter[];
  category: Filter;
  countries: Filter[];

  description: string | null;

  documentNumber: string | null;
  externalUrl: string | null;
  languages: Filter[];

  measures: Filter[];
  partNumbers: string[];
  productModels: Filter[];
  productCompanies: Filter[];
  productRanges: Filter[];
  productSubranges: Filter[];
  productRelated: boolean;
  productNames: string[];

  serialNumbers: string[];
  serviceBulletinClassification: Filter | null;
  serviceBulletinNumber: string | null;
  syncWithShowpad: boolean;

  contentType: Filter;
  validFrom: string | null;
  validTo: string | null;
  visibility: Filter;
  restrictedRoles: Filter[];
};

@Injectable({ providedIn: 'root' })
export class UploadFormDataBuilderService {
  protected uploadFormStateService = inject(UploadFormStateService);

  protected alertService = inject(AlertService);

  private formOptionsSig = this.uploadFormStateService.formOptionsSig;
  private formSig = this.uploadFormStateService.formSig;
  private formProductSig = this.uploadFormStateService.formProductSig;
  private formOrganizationSig = this.uploadFormStateService.formOrganizationSig;
  private formFileSig = this.uploadFormStateService.formFileSig;
  private http = inject(HttpClient);

  private previousFormPropertyValues: UploadForm | null = null;

  hasFormValuesBeenAlteredSig: WritableSignal<'initial' | 'altered' | 'unchanged'> =
    signal('initial');

  formPropertyValuesSig: WritableSignal<UploadForm> = signal({
    title: '',
    realFileName: '',
    audience: { code: '', name: '', value: '' },
    brands: [],
    businessLines: [],
    category: { code: '', name: '', value: '' },
    countries: [],
    description: null,
    documentNumber: null,
    externalUrl: null,
    languages: [],
    measures: [],
    partNumbers: [],
    productCompanies: [],
    productModels: [],
    productRanges: [],
    productSubranges: [],
    productNames: [],
    productRelated: false,
    serialNumbers: [],
    serviceBulletinClassification: { code: '', name: '', value: '' },
    serviceBulletinNumber: null,
    syncWithShowpad: false,
    contentType: { code: '', name: '', value: '' },
    validFrom: null,
    validTo: null,
    visibility: { code: '', name: '', value: '' },
    restrictedRoles: [],
  });

  formatDate(inputDate: string | null) {
    if (!inputDate) {
      return null;
    }
    return inputDate;
  }

  setFileTitle(value: string) {
    this.formPropertyValuesSig.set({
      ...this.formPropertyValuesSig(),
      title: value,
    });
  }

  setFileName(value: string) {
    this.formPropertyValuesSig.set({
      ...this.formPropertyValuesSig(),
      realFileName: value,
    });
  }

  setHasFormValuesBeenAlteredSig(value: 'initial' | 'altered' | 'unchanged') {
    this.hasFormValuesBeenAlteredSig.set(value);
  }

  convertFormToPropertyValues() {
    this.formPropertyValuesSig.set({
      title: '',
      realFileName: '',
      audience: findPropertyValue(this.formSig().audience, this.formOptionsSig().asset_audience),
      visibility: findPropertyValue(this.formSig().visibility, this.formOptionsSig().visibility),
      languages: filterItemsLanguage(this.formSig().languages, this.formOptionsSig().language),
      category: findPropertyValue(this.formSig().category, this.formOptionsSig().category),
      contentType: findPropertyValue(this.formSig().contentType, this.formOptionsSig().type),

      documentNumber: this.formSig().documentNumber,

      externalUrl: this.formSig().externalUrl,
      description: this.formSig().description,
      measures: filterItems(this.formSig().measures, this.formOptionsSig().measure),
      validFrom: this.formatDate(this.formSig().validFrom),
      validTo: this.formatDate(this.formSig().validTo),
      productRelated: this.formSig().productRelated,
      syncWithShowpad: this.formSig().syncWithShowPad,
      serviceBulletinNumber: this.formSig().serviceBulletinNumber,
      serviceBulletinClassification: findOptionalPropertyValue(
        this.formSig().serviceBulletinClassification ?? '',
        this.formOptionsSig().sb_classification,
      ),

      restrictedRoles: filterItems(
        this.formOrganizationSig().restrictedRoles,
        this.formOptionsSig().restricted_role,
      ),

      productCompanies: filterItems(
        this.formOrganizationSig().productCompanies,
        this.formOptionsSig().product_company,
      ),
      brands: filterItems(this.formOrganizationSig().brands, this.formOptionsSig().brand),
      businessLines: filterItems(
        this.formOrganizationSig().businessLines,
        this.formOptionsSig().business_line,
      ),
      countries: filterItems(this.formOrganizationSig().countries, this.formOptionsSig().country),

      serialNumbers: this.formProductSig().serialNumbers,
      partNumbers: this.formProductSig().partNumbers,
      productModels: filterItems(
        this.formProductSig().productModels,
        this.formOptionsSig().product_model,
      ),
      productRanges: filterItems(
        this.formProductSig().productRanges,
        this.formOptionsSig().product_range,
      ),
      productSubranges: filterItems(
        this.formProductSig().productSubranges,
        this.formOptionsSig().product_subrange,
      ),
      productNames: [],
    });

    if (this.previousFormPropertyValues) {
      const isEqual = _.isEqual(this.formPropertyValuesSig(), this.previousFormPropertyValues);
      this.hasFormValuesBeenAlteredSig.set(isEqual ? 'unchanged' : 'altered');
    }

    this.previousFormPropertyValues = this.formPropertyValuesSig();
  }

  async uploadAsset(): Promise<Asset> {
    const formData = new FormData();
    const convertedData = new Blob([JSON.stringify(this.formPropertyValuesSig())], {
      type: 'application/json',
    });

    formData.append('file', this.formFileSig().uploadFile);
    formData.append('data', convertedData);

    const res = await firstValueFrom(
      this.http.post<Asset>(`${environment.baseUrl}/assets/uploads`, formData).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          if (error.status === 413) {
            return throwError(() => new Error('File size exceeds limit (250 MB)'));
          } else {
            // Handle other errors
            return throwError(() => new Error('Something went wrong, try again'));
          }
        }),
      ),
    );
    return res;
  }

  async editAsset(id: number) {
    const formData = new FormData();
    const convertedData = new Blob([JSON.stringify(this.formPropertyValuesSig())], {
      type: 'application/json',
    });

    formData.append('data', convertedData);

    await firstValueFrom(
      this.http.put(`${environment.baseUrl}/assets/${id}`, this.formPropertyValuesSig()).pipe(
        catchError(() => {
          return throwError(() => new Error('Something went wrong, try again'));
        }),
      ),
    );
  }
}
