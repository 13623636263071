import { Component, EventEmitter, inject, OnInit, Output, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TitleGeneration,
  UploadFormDataBuilderService,
  UploadForm,
} from '@my-toolbox-fe/data-access-upload';
import { joinAndMapNames, joinAndMapValues, TYPE_SERVICE_BULLETIN } from '@my-toolbox-fe/shared';

import {
  ButtonUiComponent,
  IconEditUiComponent,
  SummaryUiComponent,
} from '@my-toolbox-fe/ui-elements';

import { Summary } from '@my-toolbox-fe/type-summary';
import { sortOnNameAlphabetically } from '@my-toolbox-fe/data-access-search';
import { Filter } from '@my-toolbox-fe/type-filter';

@Component({
  selector: 'mt-upload-form-steps-summary',
  standalone: true,
  imports: [CommonModule, ButtonUiComponent, SummaryUiComponent, IconEditUiComponent],
  templateUrl: './upload-form-steps-summary.ui-component.html',
  styleUrl: './upload-form-steps-summary.ui-component.scss',
})
export class UploadFormStepsSummaryUiComponent implements OnInit {
  @Output() showFormStepEvent: EventEmitter<number> = new EventEmitter();

  protected titleGenerationService = inject(TitleGeneration);

  protected uploadFormDataBuilderService = inject(UploadFormDataBuilderService);

  formPropertyValuesSig: WritableSignal<UploadForm> =
    this.uploadFormDataBuilderService.formPropertyValuesSig;

  fileSummary: Summary[] = [];
  productSummary: Summary[] = [];
  organizationSummary: Summary[] = [];

  ngOnInit(): void {
    this.setSummary();
    this.uploadFormDataBuilderService.setHasFormValuesBeenAlteredSig('unchanged');
  }

  onShowFormStep(formStep: number) {
    this.showFormStepEvent.emit(formStep);
  }

  setSummary() {
    this.fileSummary = [
      {
        label: 'File Title',
        value: this.formPropertyValuesSig().title,
      },
      {
        label: 'File Name',
        value: this.formPropertyValuesSig().realFileName,
      },
      {
        label: 'Audience',
        value: this.formPropertyValuesSig().audience.name,
      },
      {
        label: 'Visibility',
        value: this.formPropertyValuesSig().visibility.name,
      },
      {
        label: 'Languages',
        value: joinAndMapNames(this.formPropertyValuesSig().languages),
      },
      {
        label: 'Category',
        value: this.formPropertyValuesSig().category.name,
      },
      {
        label: 'Content Type',
        value: this.formPropertyValuesSig().contentType.name,
      },
      ...(this.formPropertyValuesSig().contentType.value === TYPE_SERVICE_BULLETIN
        ? [
            {
              label: 'Bulletin Number',
              value: this.formPropertyValuesSig().serviceBulletinNumber ?? '-',
            },
            {
              label: 'Bulletin Classification',
              value: this.formPropertyValuesSig()?.serviceBulletinClassification?.value ?? '-',
            },
          ]
        : []),
      {
        label: 'External URL',
        value: this.formPropertyValuesSig().externalUrl ?? '-',
      },
      {
        label: 'Description',
        value: this.formPropertyValuesSig().description ?? '-',
      },
      {
        label: 'Measures',
        value: joinAndMapNames(this.formPropertyValuesSig().measures),
      },
      {
        label: 'Valid From',
        value: this.formPropertyValuesSig().validFrom ?? '-',
      },
      {
        label: 'Valid To',
        value: this.formPropertyValuesSig().validTo ?? '-',
      },
      {
        label: 'Product Related',
        value: this.formPropertyValuesSig().productRelated ? 'Yes' : 'No',
      },
      {
        label: 'Sync with ShowPad',
        value: this.formPropertyValuesSig().syncWithShowpad ? 'Yes' : 'No',
      },
    ];

    this.productSummary = [
      {
        label: 'Serial Number',
        value: this.formPropertyValuesSig().serialNumbers.join(', '),
      },
      {
        label: 'Part Number',
        value: this.formPropertyValuesSig().partNumbers.join(','),
      },
      {
        label: 'Product Model',
        value: joinAndMapNames(this.formPropertyValuesSig().productModels),
      },
      {
        label: 'Product Range',
        value: joinAndMapNames(this.formPropertyValuesSig().productRanges),
      },
      {
        label: 'Product Subrange',
        value: joinAndMapNames(this.formPropertyValuesSig().productSubranges),
      },
    ];

    this.organizationSummary = [
      {
        label: 'Restricted roles',
        value: joinAndMapNames(this.formPropertyValuesSig().restrictedRoles),
      },
      {
        label: 'Product Company',
        value: joinAndMapNames(this.formPropertyValuesSig().productCompanies),
      },

      {
        label: 'Brands',
        value: joinAndMapNames(this.formPropertyValuesSig().brands),
      },
      {
        label: 'Business Lines',
        value: joinAndMapNames(this.formPropertyValuesSig().businessLines),
      },
      {
        label: 'Countries',
        value: this.sortCountriesOnName(),
      },
    ];
  }
  sortCountriesOnName() {
    const countries = this.formPropertyValuesSig().countries.sort((a: Filter, b: Filter) =>
      a.name.localeCompare(b.name),
    );
    return joinAndMapNames(countries);
  }
}
