import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from '@my-toolbox-fe/environments/environment';

import { firstValueFrom, catchError, throwError } from 'rxjs';
import { UploadFormStateService } from '../upload-form-state.service';
import { formatDate, mapValuesToArray, QB_AUDIENCE_LOCAL } from '@my-toolbox-fe/shared';
import { Asset } from '@my-toolbox-fe/type-asset';
import { replaceSpaceWithUnderscore } from '@my-toolbox-fe/data-access-search';
import { UploadFormAllowedFieldsService } from '../upload-form-allowed-fields.service';
import { UserService } from '@my-toolbox-fe/data-access-auth';
import { ROLE_UPLOADER_GLOBAL } from '@my-toolbox-fe/feat-roles';

@Injectable({ providedIn: 'root' })
export class UploadFormEditBuilderService {
  private http = inject(HttpClient);
  protected uploadFormStateService = inject(UploadFormStateService);
  protected uploadFormAllowedFieldsService = inject(UploadFormAllowedFieldsService);

  protected userService = inject(UserService);

  userSig = this.userService.currentUserSig;

  isAssetAllowedEditable: WritableSignal<boolean | null> = signal(null);
  assetPreviewSig = signal({
    name: '',
    size: '',
    type: '',
    url: '',
    extension: '',
  });

  //NOTE: Local uploaders can use the copy on global documents, the audience needs to be set to 'Local'
  private getCorrectRoleAudience(value: string) {
    if (this.userSig()?.roles.includes(ROLE_UPLOADER_GLOBAL)) {
      return value;
    }

    return QB_AUDIENCE_LOCAL;
  }

  clearIsAllowed() {
    this.isAssetAllowedEditable.set(null);
  }

  updateFormValues(values: Asset) {
    const extension = values.realFileName.split('.').pop();

    this.uploadFormStateService.setFileId(values.id);

    this.assetPreviewSig.set({
      name: values.realFileName,
      size: values.size,
      url: values.thumbnailUrl,
      extension: extension ? extension : '-',
      type: values.type.value,
    });

    this.uploadFormStateService.setProductInformation({
      serialNumbers: values.serialNumbers ? mapValuesToArray(values.serialNumbers) : [],
      partNumbers: values.partNumbers ? mapValuesToArray(values.partNumbers) : [],
      productModels: values.productModels ? mapValuesToArray(values.productModels) : [],
      productSubranges: values.productSubRanges ? mapValuesToArray(values.productSubRanges) : [],
      productRanges: values.productRanges ? mapValuesToArray(values.productRanges) : [],
      productNames: values.productNames ? mapValuesToArray(values.productNames) : [],
    });

    this.uploadFormStateService.setOrganization({
      productCompanies: values.productCompanies ? mapValuesToArray(values.productCompanies) : [],
      //NOTE: brands were previously multi select
      brands: values.brands.length > 1 ? [] : mapValuesToArray(values.brands),
      businessLines: values.businessLines ? mapValuesToArray(values.businessLines) : [],
      countries: values.countries ? mapValuesToArray(values.countries) : [],
      restrictedRoles: values.restrictedRoles ? mapValuesToArray(values.restrictedRoles) : [],
    });

    const isProductRelated = Object.values(this.uploadFormStateService.formProductSig()).some(
      (arr) => Array.isArray(arr) && arr.length > 0,
    );

    this.uploadFormStateService.setForm({
      audience: values.audience ? this.getCorrectRoleAudience(values.audience.value) : '',
      visibility: values.visibility ? values.visibility.value : '',
      languages: values.languages ? mapValuesToArray(values.languages) : [],
      category: values.category.value,
      contentType: replaceSpaceWithUnderscore(values.type.value),
      description: values.description ? values.description.value : '',
      externalUrl: values.externalUrl ? values.externalUrl.value : '',
      documentNumber: values.documentNumber ? values.documentNumber.value : '',
      serviceBulletinNumber: values.serviceBulletinNumber
        ? values.serviceBulletinNumber.value
        : values.serviceBulletinNumber,
      serviceBulletinClassification: values.serviceBulletinClassification
        ? values.serviceBulletinClassification.value
        : values.serviceBulletinClassification,
      measures: values.measures ? mapValuesToArray(values.measures) : [],
      validFrom: values.validFrom ? formatDate(values.validFrom) : '',
      validTo: values.validTo ? formatDate(values.validTo) : '',
      productRelated: isProductRelated,
      syncWithShowPad: values.syncWithShowPad.value.toLowerCase() === 'yes',
    });

    this.uploadFormStateService.setFormOptionsTypeMatchingCategory(values.category.value);

    this.uploadFormAllowedFieldsService.updateAllowedFields(
      replaceSpaceWithUnderscore(values.type.value),
    );
  }

  async getAsset(id: string) {
    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/assets/${id}?fields=ASSET`).pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        }),
      ),
    ).then((res) => {
      const result = res as Asset;
      this.updateFormValues(result);

      if (result.editable) {
        this.isAssetAllowedEditable.set(result.editable);
      }

      return result;
    });
  }
}
