import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-upload-title-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-title-label.ui-component.html',
  styleUrl: './upload-title-label.ui-component.scss',
})
export class UploadTitleLabelUiComponent {
  @Input() label = '';
  @Input() title = '';
}
