import { Component, inject, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AlertService,
  ButtonUiComponent,
  CheckboxUiComponent,
  DialogService,
  DialogUiComponent,
} from '@my-toolbox-fe/ui-elements';
import {
  TitleGeneration,
  UploadFormDataBuilderService,
  UploadFormStateService,
  UploadHistoryService,
} from '@my-toolbox-fe/data-access-upload';
import { Router } from '@angular/router';
import { FormMode } from '@my-toolbox-fe/type-upload';
import { FormsModule } from '@angular/forms';
import { UPLOAD_MODE_EDIT } from '@my-toolbox-fe/shared';
import { Asset } from '@my-toolbox-fe/type-asset';

@Component({
  selector: 'mt-upload-form-dialog',
  standalone: true,
  imports: [CommonModule, DialogUiComponent, ButtonUiComponent, CheckboxUiComponent, FormsModule],
  templateUrl: './upload-form-dialog.smart-component.html',
  styleUrl: './upload-form-dialog.smart-component.scss',
})
export class UploadFormDialogSmartComponent {
  private dialogService = inject(DialogService);

  private router: Router = inject(Router);

  protected uploadFormStateService = inject(UploadFormStateService);

  protected uploadHistoryService = inject(UploadHistoryService);

  protected uploadFormDataBuilderService = inject(UploadFormDataBuilderService);

  protected titleGenerationService = inject(TitleGeneration);

  isFormEditableSig = this.uploadFormStateService.isFormEditableSig;

  fileIdSig = this.uploadFormStateService.fileIdSig;

  formModeSig: WritableSignal<FormMode> = this.uploadFormStateService.formModeSig;
  similarUpload = false;

  private alertService = inject(AlertService);

  close() {
    this.dialogService.close();
  }

  confirm() {
    this.close();
    this.uploadFormStateService.setIsLoading(true);

    if (this.formModeSig() === UPLOAD_MODE_EDIT) {
      return this.editFile();
    }

    this.saveFile();
  }

  editFile() {
    this.uploadFormDataBuilderService
      .editAsset(this.fileIdSig())
      .then(() => {
        this.uploadFormStateService.setIsLoading(false);
        this.alertService.open({ message: 'File updated' });
        this.router.navigate(['asset', this.fileIdSig()]);
      })
      .catch((error) => {
        this.uploadFormStateService.setIsLoading(false);
        this.alertService.open({
          message: error.message,
          variant: 'danger',
        });
      });
  }

  saveFile() {
    this.uploadFormDataBuilderService
      .uploadAsset()
      .then((res) => {
        this.uploadFormStateService.setIsLoading(false);
        this.alertService.open({ message: 'File uploaded' });
        this.uploadFormStateService.setIsLoading(false);
        this.uploadFormStateService.setIsFormCompleted(true);

        if (!this.similarUpload) {
          this.uploadFormStateService.clearFormState();
        } else {
          //NOTE: Save newAsset as cache because it takes to long for qbank to publish asset -> Gives 404 on copy new document
          //NOTE: Permissions are handled by a flag in the asset body
          const newAsset = res as Asset;
          localStorage.setItem('previousAsset', JSON.stringify(newAsset));
          this.router.navigateByUrl(`asset/copy/${newAsset.id}`);
        }

        this.titleGenerationService.clearPrevDocumentInformation();
      })
      .catch((error) => {
        this.uploadFormStateService.setIsLoading(false);
        this.alertService.open({
          message: error.message,
          variant: 'danger',
        });
      });
  }
}
