<form #form="ngForm">
  <div class="product-input">
    <p>Please give a description for the product information</p>
    <div class="input">
      <mt-input
        [(ngModel)]="documentInformationInput"
        [required]="isInputRequired"
        (input)="onProductInformationEdit()"
        [maxlength]="documentInformationCorrectionLength"
        label="Document information"
        name="documentInformationCorrection"
      ></mt-input>
      @if (showReset) {
        <mt-button (submitEvent)="onReset()">Reset</mt-button>
      }
    </div>
  </div>

  <h3>Generated title and filename</h3>
  <div class="inputs">
    @if (serviceBulletinNumber) {
      <mt-upload-title-label
        [title]="serviceBulletinNumber"
        label="Service bulletin number"
      ></mt-upload-title-label>
    }

    <mt-upload-title-label
      [title]="documentInformationCorrection"
      label="Document information"
    ></mt-upload-title-label>

    @if (contentType) {
      <mt-upload-title-label [title]="contentType" label="content type"></mt-upload-title-label>
    }
    @if (languages) {
      <mt-upload-title-label [title]="languages" label="Language"></mt-upload-title-label>
    }
    @if (productCompanies) {
      <mt-upload-title-label
        [title]="productCompanies"
        label="Product companies"
      ></mt-upload-title-label>
    }
    @if (documentNumber) {
      <mt-upload-title-label
        [title]="documentNumber"
        label="Document number"
      ></mt-upload-title-label>
    }
  </div>
</form>
