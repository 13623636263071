import { AfterViewInit, Component, inject, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import {
  CheckboxUiComponent,
  InputUiComponent,
  SelectUiComponent,
  TooltipUiComponent,
} from '@my-toolbox-fe/ui-elements';
import { FormSig, Organization } from '@my-toolbox-fe/type-upload';
import { ShowFieldDirective, UploadFormStateService } from '@my-toolbox-fe/data-access-upload';
import { QB_VISIBLITY_EXTERNAL } from '@my-toolbox-fe/shared';

@Component({
  selector: 'mt-upload-form-steps-organization',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputUiComponent,
    SelectUiComponent,
    ShowFieldDirective,
    CheckboxUiComponent,
    TooltipUiComponent,
  ],
  templateUrl: './upload-form-steps-organization.smart-component.html',
  styleUrl: './upload-form-steps-organization.smart-component.scss',
})
export class UploadFormStepsOrganizationSmartComponent implements AfterViewInit, OnInit {
  @ViewChild('form') ngForm: NgForm | null = null;
  protected uploadFormStateService = inject(UploadFormStateService);

  formOrganizationSig: WritableSignal<Organization> =
    this.uploadFormStateService.formOrganizationSig;

  formSig: WritableSignal<FormSig> = this.uploadFormStateService.formSig;
  formOptionsSig = this.uploadFormStateService.formOptionsSig;

  isSingleBrandValid = this.uploadFormStateService.isSingleBrandValidSig;

  QB_VISIBLITY_EXTERNAL = QB_VISIBLITY_EXTERNAL;
  brandErrorMessage = '';

  isRestricted = false;

  tooltipContent =
    '<p>Be aware if you add a restriction, external users need to have a specific setup to access this document. For more information see the videos on <span class="bold">“How to use MyToolbox"</span></p>';

  //NOTE: ngForm stays null | undefined when calling a method but is defined in logs :)
  ngAfterViewInit(): void {
    if (!this.isSingleBrandValid() && this.formSig().productRelated) {
      setTimeout(() => {
        this.brandErrorMessage =
          'You can only select one brand. Make sure you verify the tagged product in the previous step, as currently, products of multiple brands have been selected.';
        this.ngForm?.form.markAllAsTouched();
      }, 0.1);
    }
  }

  ngOnInit(): void {
    if (this.formSig().visibility === QB_VISIBLITY_EXTERNAL) {
      this.isRestricted = (this.formOrganizationSig().restrictedRoles?.length ?? 0) > 0;
    }
  }

  onToggleRestricted() {
    if (!this.isRestricted) {
      this.uploadFormStateService.setOrganization({
        ...this.formOrganizationSig(),
        restrictedRoles: [],
      });
    }
  }
}
