import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { formatArrayValues } from '@my-toolbox-fe/data-access-search';
import { environment } from '@my-toolbox-fe/environments/environment';
import {
  ProductInformation,
  ProductSuggestion,
  ProductVerificationType,
} from '@my-toolbox-fe/type-upload';

import { catchError, firstValueFrom, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductVerificationService {
  private http = inject(HttpClient);

  isLoading = signal(false);
  productSuggestionHasErrors = signal(false);

  suggestionSummarySig: WritableSignal<{ label: string; value: string }[]> = signal([]);

  productSuggestionSig: WritableSignal<ProductSuggestion> = signal({
    suggested: {
      serialNumbers: [],
      partNumbers: [],
      models: [],
      subranges: [],
      companies: [],
      ranges: [],
      names: [],
      brands: [],
      businessLines: [],
      countries: [],
    },
    errors: [],
  });

  async getProductVerification(productInformation: ProductVerificationType) {
    this.isLoading.set(true);
    return await firstValueFrom(
      this.http
        .post(`${environment.baseUrl}/assets/uploads/product-verification`, productInformation)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          }),
        ),
    ).then((res) => {
      const results = <ProductSuggestion>res;
      this.productSuggestionSig.set(results);
      this.setHasErrors(this.productSuggestionSig().errors.length > 0);
      this.isLoading.set(false);
    });
  }

  setHasErrors(value: boolean) {
    this.productSuggestionHasErrors.set(value);
  }

  clearSuggestionSummary() {
    this.suggestionSummarySig.set([]);
  }

  clearProductSuggestion() {
    this.productSuggestionSig.set({
      suggested: {
        serialNumbers: [],
        partNumbers: [],
        companies: [],
        models: [],
        subranges: [],
        ranges: [],
        names: [],
        brands: [],
        businessLines: [],
        countries: [],
      },
      errors: [],
    });

    this.setHasErrors(false);
  }

  setSuggestionSummary(values: ProductInformation) {
    this.suggestionSummarySig.set([
      {
        label: 'Serial numbers',
        value: formatArrayValues(values.serialNumbers),
      },
      {
        label: 'Parts number',
        value: formatArrayValues(values.partNumbers),
      },
      {
        label: 'Product names',
        value: formatArrayValues(values.productNames),
      },
      {
        label: 'Product models',
        value: formatArrayValues(values.productModels),
      },
      {
        label: 'Subranges',
        value: formatArrayValues(values.productSubranges),
      },
      {
        label: 'Ranges',
        value: formatArrayValues(values.productRanges),
      },
    ]);
  }

  clearState() {
    this.clearProductSuggestion();
    this.clearSuggestionSummary();
  }
}
